@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-btn {
  @apply btn btn-neutral text-white rounded-btn;
}

.primary-btn:hover {
  @apply text-neutral bg-white;
}
.secondary-btn {
  @apply btn btn-secondary text-white rounded-btn;
}

.secondary-btn:hover {
  @apply text-secondary bg-white;
}

.card.image-full:before {
  @apply bg-black/30;
}

.menu li > *:not(ul):not(.menu-title):not(details).active,
.tooltip:before {
  @apply text-white;
}

.card {
  @apply transition-all duration-500;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.uploaded-images {
  position: relative;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}
.uploaded-images .overlay,
.uploaded-videos .overlay {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.uploaded-images .overlay svg,
.uploaded-videos .overlay svg {
  fill: #fff;
}

.uploaded-images .overlay:hover,
.uploaded-videos .overlay:hover {
  background-color: rgba(255, 0, 0, 0.7);
}
@keyframes ldio-yzaezf3dcmj {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-yzaezf3dcmj div {
  position: absolute;
  animation: ldio-yzaezf3dcmj 1s linear infinite;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #852cff;
  transform-origin: 50% 50%;
}
.loadingio-spinner-eclipse-2by998twmg8 {
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}
